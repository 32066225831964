import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import "./navbar.scss";
import useIsMobile from "../../hooks/useIsMobile";

const Nav: React.FC = () => {
    const isMobile = useIsMobile();
    const [isOpen, setIsOpen] = React.useState(false);

    const line1 = useRef<HTMLDivElement>(null);
    const line2 = useRef<HTMLDivElement>(null);
    const menuWrapper = useRef<HTMLDivElement>(null);

    const pathname = window.location.pathname;

    useLayoutEffect(() => {
        console.log(pathname);
        if (isOpen) {
            gsap.to(line1.current, {
                rotate: 45,
                y: 5,
                duration: 0.3,
                transformOrigin: "center",
            });
            gsap.to(line2.current, {
                rotate: -45,
                y: -4,
                duration: 0.3,
                transformOrigin: "center",
            });
            gsap.to(menuWrapper.current, {
                height: "100dvh",
                duration: 0.5,
                ease: "power2.inOut",
            });
            gsap.fromTo(
                ".btn-load",
                {
                    y: 20,
                    opacity: 0,
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.2,
                    ease: "power2.inOut",
                    delay: 0.5,
                    stagger: 0.1,
                }
            )
        } else {
            gsap.to(line1.current, { rotate: 0, y: 0, duration: 0.3 });
            gsap.to(line2.current, { rotate: 0, y: 0, duration: 0.3 });
            gsap.to(menuWrapper.current, {
                height: "48px",
                duration: 0.5,
                ease: "power2.inOut",
            });
        }
    }, [isOpen]);

    return (
        <>
            {isMobile ? (
                <nav
                    ref={menuWrapper}
                    className={`mobile ${isOpen ? "active" : ""}`}
                >
                    <a className="mob-logo" href="/">
                        <img src="/ui/logo-ken.svg" />
                    </a>
                    <div onClick={() => setIsOpen(!isOpen)} className="lines">
                        <div ref={line1} className="line"></div>
                        <div ref={line2} className="line"></div>
                    </div>

                    {isOpen && (
                        <>
                            <div className="menu-content">
                            <a className="btn-load" href='/building'>
                                    <h3>THE BUILDING</h3>
                                </a>
                                <a className="btn-load" href="/availability">
                                    <h3>AVAILABILITY</h3>
                                </a>
                                <a className="btn-load" href="/location">
                                    <h3>THE LOCATION</h3>
                                </a>
                                <a className="btn-load" href="/contact">
                                    <h3>CONTACT</h3>
                                </a>
                            </div>
                            <div className="download btn-load">
                                <p>DOWNLOAD</p>
                                <a
                                    href="/dl/brochure.pdf"
                                    target="_blank"
                                    className="arrow-button"
                                >
                                    <p>BROCHURE</p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="5"
                                        height="11"
                                        viewBox="0 0 5 11"
                                        fill="none"
                                    >
                                        <path
                                            d="M0 0V11L5 5.5L0 0Z"
                                            fill="#302927"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </>
                    )}
                </nav>
            ) : (
                <nav>
                    <a className={`btn-load ${pathname === "/building" ? "underline" : ""}`} href="/building" target="_top"> 
                        <p>THE BUILDING</p>
                    </a>
                    <a className={`btn-load ${pathname === "/availability" ? "underline" : ""}`} href="/availability" target="_top">
                        <p>AVAILABILITY</p>
                    </a>
                    <a href={"/"} target="_top">
                        <img src="/images/nav/logo.svg" />
                    </a>
                    <a className={`btn-load ${pathname === "/location" ? "underline" : ""}`} href="/location" target="_top">
                        <p>THE LOCATION</p>
                    </a>
                    <a className={`btn-load ${pathname === "/contact" ? "underline" : ""}`} href="/contact" target="_top">
                        <p>CONTACT</p>
                    </a>
                </nav>
            )}
        </>
    );
};

export default Nav;
