import {
    APIProvider,
    Map,
    MapCameraChangedEvent,
    Marker,
} from "@vis.gl/react-google-maps";
import "./map.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";

const MapComponent = () => {
    const [searchParams] = useSearchParams();
    const [apiLoaded, setApiLoaded] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <APIProvider
            apiKey={"AIzaSyBs9fOEZFVmtgCQT32tig-RtnryKmiWyjk"}
            onLoad={() => {
                setApiLoaded(true);
            }}
        >
            <Map
                defaultZoom={16}
                defaultCenter={{                     
                    lat: 51.50021220947593,
                    lng: -0.19133380674699596,
                }}
                fullscreenControl={false}
                disableDefaultUI={true}
                zoomControl={true}
                style={{ width: "100%", height: "90dvh" }}
                styles={[
                    {
                        featureType: "all",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "all",
                        elementType: "labels.text.stroke",
                        stylers: [
                            { visibility: "on" },
                            { color: "#3e606f" },
                            { weight: 2 },
                            { gamma: 0.84 },
                        ],
                    },
                    {
                        featureType: "all",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "administrative",
                        elementType: "geometry",
                        stylers: [{ weight: 0.6 }, { color: "#1a3541" }],
                    },
                    {
                        featureType: "landscape",
                        elementType: "geometry",
                        stylers: [{ color: "#2c5a71" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [{ color: "#406d80" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#2c5a71" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#29768a" }, { lightness: -37 }],
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#406d80" }],
                    },
                    {
                        featureType: "transit.station.rail",
                        elementType: "all",
                        stylers: [{ visibility: "on" }],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#193341" }],
                    },
                ]}
            >
                {apiLoaded && window.google && (
                    <>
                    <Marker
                        title="Default Location"
                        position={{ lat: 51.50021220947593, lng: -0.19133380674699596 }}
                        icon={{
                            url: "/ui/map-marker.svg",
                            scaledSize: new window.google.maps.Size(150, 150),
                            anchor: new window.google.maps.Point(75, 150),
                        }}
                    />
                    </>
                )}
            </Map>
        </APIProvider>
    );
};

export default MapComponent;
