import { useLayoutEffect } from "react";
import "./contact.scss";
import gsap from "gsap";

const Contact = () => {
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".intro-overlay", {
                opacity: 0,
                duration: 1,
                ease: "power1.in",
                delay: 0.25,
                onComplete: () => {
                    gsap.set(".intro-overlay", { display: "none" });
                },
            });
        });
        return () => ctx.revert();
    }, []);

    return (
        <div className="contact-one">
            <div className="intro-overlay"></div>
            <h2>
                To discover more, book a viewing
                <br />
                with one of our agents below
            </h2>
        </div>
    );
};

export default Contact;
