import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./gallery.scss";

interface GalleryProps {
    images: string[];
    captions: {
        duration: string;
        location: string;
    }[];
    copy: {
        heading: string;
        copy: string;
    };
    id: string;
}

const LocationGallery: React.FC<GalleryProps> = ({
    images,
    captions,
    copy,
    id

}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <>
            <Swiper
                id={'location-gallery-' + id}
                slidesPerView={1}
                centeredSlides={true}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                navigation={{
                    nextEl: `.button-next-${id}`,
                    prevEl: `.button-prev-${id}`,
                }}
                onSlideChange={(swiper: any) =>
                    setCurrentSlide(swiper.realIndex)
                }
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className="image-wrapper"
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="copy-wrapper">
                <div className="copy">
                    <h3>{copy.heading}</h3>
                    <p>{copy.copy}</p>
                </div>
                <div className="nav-wrapper">
                    <div className="place">
                        <p>
                            <span>{captions[currentSlide].duration}</span> MINS
                        </p>
                        <h3>{captions[currentSlide].location.toUpperCase()}</h3>
                    </div>
                    <div className="arrows">
                        <svg
                            className={`button-prev-${id}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="30"
                            viewBox="0 0 40 30"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_6042_13117)">
                                <path
                                    d="M30.1592 14.7395L1.46448 14.7395M1.25528 14.7395L38.928 28.6162L29.9587 14.7395L38.928 0.862887L1.264 14.7395L1.25528 14.7395Z"
                                    stroke="#E6E0CC"
                                    stroke-miterlimit="10"
                                    fill='transparent'
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_6042_13117">
                                    <rect
                                        width="40"
                                        height="29.4792"
                                        fill="white"
                                        transform="translate(40 29.4792) rotate(-180)"
                                        
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="30"
                            viewBox="0 0 40 30"
                            fill="none"
                            className={`button-next-${id} second`}
                        >
                            <g clip-path="url(#clip0_6042_13117)">
                                <path
                                    d="M30.1592 14.7395L1.46448 14.7395M1.25528 14.7395L38.928 28.6162L29.9587 14.7395L38.928 0.862887L1.264 14.7395L1.25528 14.7395Z"
                                    stroke="#E6E0CC"
                                    stroke-miterlimit="10"
                                    fill='transparent'
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_6042_13117">
                                    <rect
                                        width="40"
                                        height="29.4792"
                                        fill="white"
                                        transform="translate(40 29.4792) rotate(-180)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LocationGallery;
