import { useLayoutEffect, useState } from "react";
import gsap from "gsap";
import Gallery from "../../components/gallery/gallery";
import "./building.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);
const Building = () => {
    const [specification, setSpecification] = useState<boolean>(true);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".intro-overlay", {
                opacity: 0,
                duration: 1,
                ease: "power1.in",
                delay: 0.25,
                onComplete: () => {
                    gsap.set(".intro-overlay", { display: "none" });
                },
            });

            gsap.delayedCall(0.2, () => {
                let split = new SplitText(".text-to-animate", {
                    type: "lines",
                });
                gsap.from(split.lines, {
                    scrollTrigger: {
                        trigger: ".text-to-animate", // Pin the text, not the wrapper
                        start: "top center-=64", // Start when the text reaches the middle
                        end: "bottom bottom", // Animate over 100vh
                        scrub: 1,
                        scroller: "#smooth-wrapper",
                        pin: true,
                        pinSpacing: true, // Ensure other content isn't pushed oddly
                    },
                    opacity: 0,
                    y: 0,
                    ease: "back",

                    stagger: 0.2,
                });
            });
            ScrollTrigger.normalizeScroll(true);

            ScrollTrigger.refresh();
        });

        return () => ctx.revert();
    }, []);

    return (
        <>
            <div
                className="building-one"
                style={{ backgroundImage: "url(/images/building/1.jpg)" }}
            >
                <div className="intro-overlay"></div>

                <div className="circle">
                    <div className="copy">
                        <h4>THE BUILDING</h4>
                        <h1>
                            TAILORED FOR <br /> DISCRETION
                        </h1>
                    </div>
                </div>
            </div>
            <div className="building-two">
                <div className="text-wrapper">
                    <h3 className="text-to-animate">
                        Experience the fresh allure of One Derry Street’s
                        vibrant green walkway. The lush surroundings provide a
                        welcoming arrival experience that perfectly blends
                        nature with modern design.
                    </h3>
                </div>
            </div>
            <div
                className="building-three"
                style={{ backgroundImage: "url(/images/building/2.jpg)" }}
            >
                <p className="caption">Pocket Park</p>
            </div>

            <div className="building-four">
                <div className="wrapper">
                    <h3>
                        FIRST IMPRESSIONS
                        <br />
                        COUNT
                    </h3>
                    <p>
                        One Derry Street is set back from Kensington High Street
                        and accessed via its own private ‘Pocket Park’.
                        <br />
                        <br />
                        Emerging from the walkway, you are greeted by a
                        contemporary remodelled entrance beyond a landscaped
                        courtyard.
                    </p>
                </div>
                <div
                    className="image-wrapper"
                    style={{ backgroundImage: "url(/images/building/3.jpg)" }}
                >
                    {" "}
                    <p className="caption">Entrance Arrival</p>
                </div>
            </div>

            <div className="building-five">
                <div
                    className="image-wrapper"
                    style={{ backgroundImage: "url(/images/building/4.jpg)" }}
                >
                    {" "}
                    <p className="caption">Ground Floor Reception</p>
                </div>
            </div>
            <div className="building-six">
                <h3>DRESSED TO IMPRESS</h3>
                <p>
                    Once inside the building, you will experience contemporary
                    finishes and reimagined warehouse space.
                </p>
            </div>

            <div className="building-seven">
                <Gallery
                    images={[
                        "/images/building/gallery/1.jpg",
                        "/images/building/gallery/2.jpg",
                        "/images/building/gallery/3.jpg",
                        "/images/building/gallery/4.jpg",
                        "/images/building/gallery/5.jpg",
                        "/images/building/gallery/6.jpg",
                    ]}
                    captions={[
                        "Ground Floor Reception",
                        "Ground Floor Reception",
                        "Ground Floor Reception",
                        "Exterior",
                        "Ground Floor Reception",
                        "Ground Floor Reception",
                    ]}
                />
            </div>
            <div className="building-eight">
                <div className="title-wrapper">
                    <h3 className="brown">SPECIFICATION</h3>
                    <div className="button-wrapper">
                        <div
                            onClick={() => setSpecification(true)}
                            className={`arrow-button ${
                                specification ? "btn-brown" : ""
                            }`}
                        >
                            <p>THE BUILDING</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" />
                            </svg>
                        </div>
                        <div
                            onClick={() => setSpecification(false)}
                            className={`arrow-button ${
                                !specification ? "btn-brown" : ""
                            }`}
                        >
                            <p>SUSTAINABILITY</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    className={`specification ${
                        !specification ? "sustain" : ""
                    }`}
                >
                    {specification ? (
                        <>
                            <div className="row">
                                <h3>THE BUILDING</h3>
                                <div className="icon-wrapper">
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/1.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>Occupational density of 1:8 sq. m</p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/2.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>13 Underground car parking spaces</p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/3.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            Basement space provided for occupier
                                            storage or gym
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/4.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            3.22m to 3.81m raised floor to
                                            soffit
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h3></h3>
                                <div className="icon-wrapper">
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/5.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            1,700 sq ft entertainment-standard
                                            roof terrace, accessed via lift
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/6.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>Electric vehicle charging points</p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/7.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            Exposed VRF heating/cooling with
                                            fresh air, supported by openable
                                            windows
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/8.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>Full fibre connectivity</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <h3></h3>
                                <div className="icon-wrapper">
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/9.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>63 Basement bicycle spaces</p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/10.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>2 X 13-person passenger lifts</p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/11.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>Dimmable LED lighting</p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/12.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            Club-standard changing facilities
                                            with 88 lockers, 8 showers / vanity
                                            stations & towel service
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row">
                                <h3>SUSTAINABILITY</h3>
                                <div className="icon-wrapper">
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/13.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            COMPREHENSIVELY REFURBISHED
                                            <br />
                                            <br />
                                            Retrofit carbon embodied emissions:
                                            <br />
                                            207kCO2e/m2 as compared to the 2030
                                            LETI design target for new build
                                            office building of less than
                                            350KgCO2e/m2.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/14.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            ELECTRIC HEATING AND COOLING
                                            <br />
                                            <br />
                                            90m2 new PV panels installed. All
                                            energy used at the building is and
                                            will be renewably sourced reducing
                                            operational carbon.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/15.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            CYCLE PARKING
                                            <br />
                                            <br />
                                            Plus electric charging points for
                                            electric vehicles of all kinds.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h3></h3>
                                <div className="icon-wrapper">
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/16.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            EPC RATING A / BREEAM EXCELLENT
                                            <br />
                                            <br />
                                            At design stage, potentially
                                            increasing upon completion.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/17.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            OPENABLE WINDOWS
                                            <br />
                                            <br />
                                            secondary glazing, and newly
                                            insulated roof.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/18.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            GREEN PLANTED SPACES
                                            <br />
                                            <br />
                                            and entertainment standard roof
                                            terrace.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h3></h3>
                                <div className="icon-wrapper">
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/19.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            LED LIGHTING
                                            <br />
                                            <br />
                                            low energy dimmable.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/20.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            SHOWERS AND CHANGING
                                            <br />
                                            <br />
                                            encouraging sustainable travel
                                            options. A towel service and drying
                                            cupboards will be provided.
                                        </p>
                                    </div>
                                    <div className="icon">
                                        <div className="image-wrapper">
                                            <img
                                                src="images/building/icons/21.svg"
                                                alt="icon"
                                            />
                                        </div>
                                        <p>
                                            VENTILATION SYSTEM
                                            <br />
                                            <br />
                                            on each floor, saving fan and vrf
                                            heat/cool power.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="building-nine">
                <div
                    className="image-wrapper"
                    style={{ backgroundImage: "url(/images/building/5.jpg)" }}
                >
                    <p className="caption flip">Ground Floor Courtyard</p>
                </div>
                <div className="copy-wrapper">
                    <h3>OUTSIDE AREAS</h3>
                    <p>
                        There are theree bright and spacious courtyards
                        accessible from the ground floor and an events-ready
                        roof garden providing a private venue to relax,
                        entertain and bask in lush, green surroundings.
                    </p>
                </div>
            </div>
            <div className="building-ten">
                <div
                    className="image-wrapper"
                    style={{ backgroundImage: "url(/images/building/6.jpg)" }}
                >
                    {" "}
                    <p className="caption">Roof Terrace</p>
                </div>
            </div>
            <div className="building-nine two">
                <div
                    className="image-wrapper"
                    style={{ backgroundImage: "url(/images/building/7.jpg)" }}
                >
                    <p className="caption flip">
                        End of-journey facilities and vehicular access
                    </p>
                </div>
                <div className="copy-wrapper">
                    <h3>IN THE DETAIL</h3>
                    <p>
                        Every detail has been considered and the building is
                        equipped with everything a modern occupier could need,
                        including parking spaces and end-of-journey facilities.
                    </p>
                </div>
            </div>
            <div className="building-eleven">
                <Gallery
                    images={[
                        "/images/building/gallery2/1.jpg",
                        "/images/building/gallery2/2.jpg",
                        "/images/building/gallery2/3.jpg",
                        "/images/building/gallery2/4.jpg",
                        "/images/building/gallery2/5.jpg",
                        "/images/building/gallery2/6.jpg",
                    ]}
                    captions={[
                        "End of-journey facilities",
                        "End of-journey facilities",
                        "End of-journey facilities",
                        "Entrance Arrival",
                        "Stairwell",
                        "End of-journey facilities",
                    ]}
                />
            </div>
        </>
    );
};

export default Building;
