import { useRef, useState } from "react";
import "./floorplans.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { smoother } from "../../app";

const floorplans = [
    "/images/availability/floorplans/4.svg",
    "/images/availability/floorplans/1.svg",
    "/images/availability/floorplans/1.svg",
    "/images/availability/floorplans/1.svg",
    "/images/availability/floorplans/0.svg",
    "/images/availability/floorplans/11.svg",
];

interface floorplanDetail {
    floor: string;
    workspace?: {
        sqft: string;
        sqm: string;
    };
    courtyard?: {
        sqft: string;
        sqm: string;
    };
}

const floorplanDetails: floorplanDetail[] = [
    {
        floor: "ROOF",

        courtyard: {
            sqft: "2,164",
            sqm: "201",
        },
    },
    {
        floor: "THIRD",
        workspace: {
            sqft: "6,817",
            sqm: "633.3",
        },
    },
    {
        floor: "SECOND",
        workspace: {
            sqft: "7,906",
            sqm: "734.5",
        },
    },

    {
        floor: "FIRST",
        workspace: {
            sqft: "8,309",
            sqm: "771.9",
        },
    },

    {
        floor: "GROUND",
        workspace: {
            sqft: "10,441",
            sqm: "970",
        },
        courtyard: {
            sqft: "2,897",
            sqm: "269",
        },
    },

    {
        floor: "BASEMENT",
    },
];

const Floorplans = () => {
    const [swiperIndex, setSwiperIndex] = useState(0);
    const swiperRef = useRef<any>(null);

    const handleSlideChange = (swiper: any) => {
        setSwiperIndex(swiper.activeIndex);
    };

    const navigateToSlide = (index: number) => {
        if (swiperRef.current) {
            swiperRef.current.slideTo(index);
        }
    };

    const scrollToFloorplan = () => {
        const element = document.getElementById("floorplans");
        if (element) {
            if (smoother) {
                smoother.scrollTo(element, true, "top 20px"); // Smooth scroll with offset
            }
        }
    };

    const handleIncrementSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    const handleDecrementSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    return (
        <>
            <div className="floorplan-table-section">
                <div className="table">
                    <div className="row brown">
                        <div className="cell">
                            <h6>FLOOR</h6>
                        </div>
                        <div className="cell">
                            <h6>
                                OFFICE SPACE
                                <br />
                                <br />
                                SQ FT
                            </h6>
                        </div>
                        <div className="cell">
                            <h6>
                                TERRACE & COURTYARDS
                                <br />
                                <br />
                                SQ FT
                            </h6>
                        </div>
                    </div>
                    <div className="row disabled">
                        <div className="cell">
                            <h6>ROOF TERRACE</h6>
                        </div>
                        <div className="cell">
                            <h6>-</h6>
                        </div>
                        <div className="cell">
                            <h6>2,164</h6>
                        </div>
                    </div>
                    <div
                        className="row"
                        onClick={() => {
                            navigateToSlide(1);
                            scrollToFloorplan();
                        }}
                    >
                        <div className="cell">
                            <h6>THIRD</h6>
                        </div>
                        <div className="cell">
                            <h6>6,817</h6>
                        </div>
                        <div className="cell">
                            <h6>-</h6>
                        </div>
                    </div>
                    <div
                        className="row"
                        onClick={() => {
                            navigateToSlide(2);
                            scrollToFloorplan();
                        }}
                    >
                        <div className="cell">
                            <h6>SECOND</h6>
                        </div>
                        <div className="cell">
                            <h6>7,906</h6>
                        </div>
                        <div className="cell">
                            <h6>-</h6>
                        </div>
                    </div>
                    <div
                        className="row"
                        onClick={() => {
                            navigateToSlide(3);
                            scrollToFloorplan();
                        }}
                    >
                        <div className="cell">
                            <h6>FIRST</h6>
                        </div>
                        <div className="cell">
                            <h6>8,309</h6>
                        </div>
                        <div className="cell">
                            <h6>-</h6>
                        </div>
                    </div>
                    <div
                        className="row"
                        onClick={() => {
                            navigateToSlide(4);
                            scrollToFloorplan();
                        }}
                    >
                        <div className="cell">
                            <h6>GROUND</h6>
                        </div>
                        <div className="cell">
                            <h6>10,441</h6>
                        </div>
                        <div className="cell">
                            <h6>2,897</h6>
                        </div>
                    </div>
                    <div className="row orange">
                        <div className="cell">
                            <h6>Total</h6>
                        </div>
                        <div className="cell">
                            <h6>33,473</h6>
                        </div>
                        <div className="cell">
                            <h6>5,061</h6>
                        </div>
                    </div>
                </div>
                <div
                    className="image-wrapper"
                    style={{
                        backgroundImage:
                            "url(/images/availability/floorplans/1.jpg)",
                    }}
                ></div>
            </div>
            <div className="floorplans" id="floorplans">
                <div className="title-wrapper">
                    <h3>FLOOR PLANS</h3>
                </div>
                <div className="floorplans-wrapper">
                    <div className="floorplan-detail">
                        <div className="copy-wrapper">
                            <div className="title-container">
                                <h3>{floorplanDetails[swiperIndex].floor}</h3>
                                {swiperIndex != 5 ? (
                                    <div className="content-wrapper">
                                        {floorplanDetails[swiperIndex]
                                            .workspace && (
                                            <>
                                                <div className="content">
                                                    <p>WORKSPACE</p>
                                                    <h3>
                                                        {
                                                            floorplanDetails[
                                                                swiperIndex
                                                            ].workspace?.sqft
                                                        }{" "}
                                                        sq ft
                                                    </h3>
                                                    <h3>
                                                        {
                                                            floorplanDetails[
                                                                swiperIndex
                                                            ].workspace?.sqm
                                                        }{" "}
                                                        sq m
                                                    </h3>
                                                </div>
                                            </>
                                        )}
                                        <div className="content">
                                            {floorplanDetails[swiperIndex]
                                                .courtyard && (
                                                <>
                                                    <p>COURTYARD</p>
                                                    <h3>
                                                        {
                                                            floorplanDetails[
                                                                swiperIndex
                                                            ].courtyard?.sqft
                                                        }{" "}
                                                        sq ft
                                                    </h3>
                                                    <h3>
                                                        {
                                                            floorplanDetails[
                                                                swiperIndex
                                                            ].courtyard?.sqm
                                                        }{" "}
                                                        sq m
                                                    </h3>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <h5>13 Car parking spaces</h5>
                                            <h5>63 Cycle Storage spaces</h5>
                                            <h5>8 Showers</h5>
                                            <h5>4 WCs</h5>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="nav-wrapper">
                                <div className="buttons">
                                    {floorplanDetails.map((detail, index) => (
                                        <div
                                            key={index}
                                            onClick={() =>
                                                navigateToSlide(index)
                                            }
                                            className={`arrow-button ${
                                                swiperIndex === index
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <p>{detail.floor}</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="5"
                                                height="11"
                                                viewBox="0 0 5 11"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0 0V11L5 5.5L0 0Z"
                                                    fill="#302927"
                                                />
                                            </svg>
                                        </div>
                                    ))}
                                </div>

                                <div className="key">
                                    {swiperIndex != 5 ? (
                                        <>
                                            <div className="row">
                                                <div className="circle lime"></div>
                                                <p>COURTYARD</p>
                                            </div>
                                            <div className="row">
                                                <div className="circle"></div>
                                                <p>OFFICE</p>
                                            </div>
                                            <div className="row">
                                                <div className="circle beige"></div>
                                                <p>CORE</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="circle cyan"></div>
                                                <p>CAR PARKING</p>
                                            </div>
                                            <div className="row">
                                                <div className="circle cycle"></div>
                                                <p>CYCLE STORAGE</p>
                                            </div>
                                            <div className="row">
                                                <div className="circle beige"></div>
                                                <p>CORE / CHANGING</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={handleSlideChange}
                        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
                        initialSlide={swiperIndex}
                        id="floorplans"
                    >
                        {floorplans.map((floorplan, index) => (
                            <SwiperSlide key={index}>
                                <img src={floorplan} alt="Floorplan" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="content-wrapper-mb">
                        {floorplanDetails[swiperIndex].workspace && (
                            <div className="content">
                                <p>WORKSPACE</p>
                                <h3>
                                    {
                                        floorplanDetails[swiperIndex].workspace
                                            ?.sqft
                                    }{" "}
                                    sq ft
                                </h3>
                                <h3>
                                    {
                                        floorplanDetails[swiperIndex].workspace
                                            ?.sqm
                                    }{" "}
                                    sq m
                                </h3>
                            </div>
                        )}
                            {floorplanDetails[swiperIndex].courtyard && (
                        <div className="content">
                                <>
                                    <p>COURTYARD</p>
                                    <h3>
                                        {
                                            floorplanDetails[swiperIndex]
                                                .courtyard?.sqft
                                        }{" "}
                                        sq ft
                                    </h3>
                                    <h3>
                                        {
                                            floorplanDetails[swiperIndex]
                                                .courtyard?.sqm
                                        }{" "}
                                        sq m
                                    </h3>
                                </>
                        </div>
                            )}

                        {swiperIndex === 5 && (
                            <div className="content">
                                <h5>13 Car parking spaces</h5>
                                <h5>63 Cycle Storage spaces</h5>
                                <h5>8 Showers</h5>
                                <h5>4 Wcs</h5>
                            </div>
                        )}
                    </div>
                    <div className="mobile-arrows">
                        <div
                            className="arrow"
                            onClick={() => handleDecrementSlide()}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="30"
                                viewBox="0 0 40 30"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_6042_13117)">
                                    <path
                                        d="M30.1592 14.7395L1.46448 14.7395M1.25528 14.7395L38.928 28.6162L29.9587 14.7395L38.928 0.862887L1.264 14.7395L1.25528 14.7395Z"
                                        stroke="#302927"
                                        stroke-miterlimit="10"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6042_13117">
                                        <rect
                                            width="40"
                                            height="29.4792"
                                            fill="wh302927ite"
                                            transform="translate(40 29.4792) rotate(-180)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div
                            className="arrow-prev"
                            onClick={() => handleIncrementSlide()}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="30"
                                viewBox="0 0 40 30"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_6042_13117)">
                                    <path
                                        d="M30.1592 14.7395L1.46448 14.7395M1.25528 14.7395L38.928 28.6162L29.9587 14.7395L38.928 0.862887L1.264 14.7395L1.25528 14.7395Z"
                                        stroke="#302927"
                                        stroke-miterlimit="10"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6042_13117">
                                        <rect
                                            width="40"
                                            height="29.4792"
                                            fill="302927"
                                            transform="translate(40 29.4792) rotate(-180)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Floorplans;
