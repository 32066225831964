import React, { useEffect, useLayoutEffect, useRef } from "react";
import "../../scss/global.scss";
import "./home.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { Link } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
gsap.registerPlugin(ScrollTrigger, SplitText);

const Home: React.FC = () => {
    const isMobile = useIsMobile();
    const timeline = useRef<gsap.core.Timeline | null>(null);
    useLayoutEffect(() => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

        const ctx = gsap.context(() => {
            gsap.to(".intro-overlay", {
                opacity: 0,
                duration: 1,
                ease: "power1.in",
                delay: 0.5,
                onComplete: () => {
                    gsap.set(".intro-overlay", { display: "none" });
                },
            });

            timeline.current = gsap.timeline({
                scrollTrigger: {
                    trigger: ".image-wrapper",
                    start: "top top",
                    end: "bottom top",
                    scrub: 2,
                    pin: true,
                    scroller: "#smooth-wrapper",
                },
            });

            timeline.current
                .fromTo(
                    ".brown",
                    { z: 0 },
                    { z: 800, ease: "power2.out", duration: 3 }
                )
                .fromTo(
                    ".light-green",
                    { z: 0 },
                    { z: 700, ease: "power2.out", duration: 3 },
                    "<0.3"
                )
                .fromTo(
                    ".dark-green",
                    { z: 0 },
                    { z: 700, ease: "power2.out", duration: 3 },
                    "<0.2"
                )
                .fromTo(
                    ".orange",
                    { z: 0 },
                    { z: 600, y: 100, ease: "power2.out", duration: 3 },
                    "<0.2"
                )
                .to(
                    ".circle",
                    {
                        height: isMobile ? "100vh" : "100vw",
                        ease: "sine.out",
                        duration: 4,
                    },
                    "<0.2"
                )
                .to(
                    ".circle-copy",
                    { width: "500px", ease: "sine.out", duration: 4 },
                    "<0.2"
                )
                .to(".circle", { borderRadius: "0", ease: "power1.out" }, "<3")
                .to(".copy", { opacity: 0 }, "<1")
                .to(".copy-two", { opacity: 1, duration: 10 }, "<0.5")
                .to(".intro-animation", { backgroundColor: "#302927" }, "<");

            gsap.to(".copy-two", {
                opacity: 0,
                scrollTrigger: {
                    trigger: ".copy-two",
                    start: "top-=200px top",
                    end: "center top",
                    scrub: true,
                    scroller: "#smooth-wrapper",
                },
            });

            // gsap.to(".parallax-image", {
            //     y: isMobile ? 0 : -200,
            //     scale: 1,
            //     scrollTrigger: {
            //         trigger: ".parallax-section",
            //         start: "top bottom",
            //         end: "top top",
            //         scrub: true,
            //         scroller: "#smooth-wrapper",
            //     },
            // });

            gsap.delayedCall(0.2, () => {
                let split = new SplitText(".text-to-animate", {
                    type: "lines",
                });

                gsap.from(split.lines, {
                    scrollTrigger: {
                        trigger: ".text-to-animate", // Pin the text, not the wrapper
                        start: "top center-=128", // Start when the text reaches the middle
                        end: "bottom bottom", // Animate over 100vh
                        scrub: 1,
                        scroller: "#smooth-wrapper",
                        pin: true,
                        pinSpacing: true, // Ensure other content isn't pushed oddly
                    },
                    opacity: 0,
                    y: 0,
                    ease: "back",
                    stagger: 0.2,
                });
            });

            if (isMobile) {
                document.querySelectorAll(".nav-section").forEach((section) => {
                    gsap.to(section, {
                        scrollTrigger: {
                            trigger: section,
                            start: "top center",
                            end: "bottom center",
                            scrub: 1,
                            scroller: "#smooth-wrapper",
                            toggleActions: "play reverse play reverse",
                        },
                        backgroundColor: "#ec6332",
                        opacity: 1,
                    });

                    const button = section.querySelector(".arrow-button");
                    if (button) {
                        gsap.to(button, {
                            scrollTrigger: {
                                trigger: section,
                                start: "top center",
                                end: "bottom center",
                                scrub: 1,
                                scroller: "#smooth-wrapper",
                                toggleActions: "play reverse play reverse",
                            },
                            backgroundColor: "#302927", // Change this to the desired color
                            borderColor: "#ec6332",
                        });
                    }

                    const copy = section.querySelector("p");
                    if (copy) {
                        gsap.to(copy, {
                            scrollTrigger: {
                                trigger: section,
                                start: "top center",
                                end: "bottom center",
                                scrub: 1,
                                scroller: "#smooth-wrapper",
                                toggleActions: "play reverse play reverse",
                            },
                            color: "#ec6332", // Change this to the desired color
                        });
                    }
                    const path = section.querySelector("path");
                    if (path) {
                        gsap.to(path, {
                            scrollTrigger: {
                                trigger: section,
                                start: "top center",
                                end: "bottom center",
                                scrub: 1,
                                scroller: "#smooth-wrapper",
                                toggleActions: "play reverse play reverse",
                            },
                            fill: "#ec6332", // Change this to the desired color
                        });
                    }
                });
            }

            gsap.to(".parallax-section", {
                scrollTrigger: {
                    trigger: ".parallax-wrapper",
                    start: "top top",
                    end: "+=100%",
                    scroller: "#smooth-wrapper",
                    pin: true,
                    scrub: 1,
                    pinSpacing: false,
                },
            });

            ScrollTrigger.normalizeScroll(true);

            ScrollTrigger.refresh();
        });

        return () => ctx.revert();
    }, [isMobile]);

    return (
        <>
            <div className="home-one">
                <div className="intro-animation">
                    <div className="intro-overlay"> </div>

                    <div className="image-wrapper">
                        <img
                            className="brown borders"
                            src="/images/brown.svg"
                        />
                        <img
                            className="light-green borders"
                            src="/images/light-green.svg"
                        />
                        <img
                            className="dark-green borders"
                            src="/images/dark-green.svg"
                        />
                        <img
                            className="orange borders"
                            src="../images/orange.svg"
                        />
                        <div className="circle">
                            <div className="copy circle-copy">
                                <img
                                    className=" image-wrapper"
                                    src="/ui/intro-logo.svg"
                                    alt="intro"
                                />
                            </div>
                            <div className="copy-two">
                                <h1>
                                    CUT FROM A<br />
                                    DIFFERENT CLOTH
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-two">
                <div className="text-wrapper">
                    <h3 className="text-to-animate">
                        A unique thread in the fabric of Kensington history, One
                        Derry Street sits on the site where talented workers,
                        seamstresses and tailors lived and worked, filling the
                        floors of Derry & Tom’s department store with the
                        clothes that spilled out onto the fashionable streets of
                        Kensington.
                       <br/>
                       <br/>
                        Now a contemporary warehouse workspace of 30,000 sq ft,
                        the building offers a unique headquarters opportunity
                        for the next generation of makers, doers and thinkers.
                    </h3>
                </div>
            </div>
            <div className="parallax-wrapper">
                <div
                    className="home-three parallax-section "
                    // style={{ backgroundImage: "url(images/home/1.jpg)" }}
                >
                    <div
                        className="main-image-wrapper parallax-image"
                        style={{ backgroundImage: "url(images/home/1.jpg)" }}
                    ></div>
                </div>
            </div>

            <div className="home-four">
                <Link to={"/building"} target="_top" className="nav-section">
                    <div className="copy-wrapper">
                        <h2>THE BUILDING</h2>
                        <div className="arrow-button btn-stone">
                            <p className="brown">DISCOVER</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" fill="#302927" />
                            </svg>
                        </div>
                    </div>
                    <div
                        className="image-section"
                        style={{
                            backgroundImage: "url(/images/home/nav/1.jpg)",
                        }}
                    ></div>
                </Link>
                <Link to={"/location"} target="_top" className="nav-section">
                    <div className="copy-wrapper">
                        <h2>LOCATION</h2>
                        <div className="arrow-button btn-stone">
                            <p className="brown">LOCATION</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" fill="#302927" />
                            </svg>
                        </div>
                    </div>
                    <div
                        className="image-section"
                        style={{
                            backgroundImage: "url(/images/home/nav/2.jpg)",
                        }}
                    ></div>
                </Link>
                <Link to={"/availability"} target="_top" className="nav-section">
                    <div className="copy-wrapper">
                        <h2>AVAILABILITY</h2>
                        <div className="arrow-button btn-stone">
                            <p className="brown">AVAILABILITY</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" fill="#302927" />
                            </svg>
                        </div>
                    </div>
                    <div
                        className="image-section"
                        style={{
                            backgroundImage: "url(/images/home/nav/3.jpg)",
                        }}
                    ></div>
                </Link>
            </div>
        </>
    );
};

export default Home;
