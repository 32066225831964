import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./gallery.scss";
import useIsMobile from "../../hooks/useIsMobile";

interface GalleryProps {
    images: string[];
    captions: string[];
}

const AvailabilityGallery: React.FC<GalleryProps> = ({ images, captions }) => {
    const [swiperIndex, setSwiperIndex] = useState(0);

    return (
        <>
            <Swiper
                id="Availability-gallery"
                spaceBetween={16}
                centeredSlides={true}
                loop={true}
                navigation={{
                    nextEl: ".next",
                    prevEl: ".prev",
                }}
                breakpoints={{
                    768: {
                        slidesPerView: 1,
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                }}
                onSlideChange={(swiper: any) =>
                    setSwiperIndex(swiper.realIndex)
                }
                modules={[Autoplay, Pagination, Navigation]}
            >
                {images.map((image, index) => (
                    <>
                        <SwiperSlide key={index}>
                            <div
                                className="image-wrapper"
                                style={{ backgroundImage: `url(${image})` }}
                            >
                                <p className="caption flip">{captions[index]}</p>
                                </div>
                        </SwiperSlide>
                    </>
                ))}
                {useIsMobile() && (
                    <div className="caption-wrapper">
                        <h3>{captions[swiperIndex]}</h3>
                    </div>
                )}
                <div className="nav-wrapper">
                    <div className="prev">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="30"
                            viewBox="0 0 40 30"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_6042_13117)">
                                <path
                                    d="M30.1592 14.7395L1.46448 14.7395M1.25528 14.7395L38.928 28.6162L29.9587 14.7395L38.928 0.862887L1.264 14.7395L1.25528 14.7395Z"
                                    stroke="#302927"
                                    stroke-miterlimit="10"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_6042_13117">
                                    <rect
                                        width="40"
                                        height="29.4792"
                                        fill="white"
                                        transform="translate(40 29.4792) rotate(-180)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="next">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="30"
                            viewBox="0 0 40 30"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_6042_13117)">
                                <path
                                    d="M30.1592 14.7395L1.46448 14.7395M1.25528 14.7395L38.928 28.6162L29.9587 14.7395L38.928 0.862887L1.264 14.7395L1.25528 14.7395Z"
                                    stroke="#302927"
                                    stroke-miterlimit="10"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_6042_13117">
                                    <rect
                                        width="40"
                                        height="29.4792"
                                        fill="white"
                                        transform="translate(40 29.4792) rotate(-180)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </Swiper>
        </>
    );
};

export default AvailabilityGallery;
