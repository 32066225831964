import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import "./scss/global.scss";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import Building from "./pages/building/building";
import Location from "./pages/location/location";
import Contact from "./pages/contact/contact";
import Availability from "./pages/availability/availability";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export let smoother : ScrollSmoother | null = null;

const App: React.FC = () => {
    const routes = [
        { path: "/", component: Home },
        { path: "/building", component: Building },
        { path: "/location", component: Location },
        { path: "/availability", component: Availability },
        { path: "/contact", component: Contact },
    ];

    useEffect(() => {
        smoother = ScrollSmoother.create({
            wrapper: "#smooth-wrapper",
            content: "#smooth-content",
            smooth: 1.5,
            effects: true,
            smoothTouch: 0.1,
        });

        ScrollTrigger.refresh();

        return () => {
            if (smoother) {
                smoother.kill();
            }
        };
    }, []);

    return (
        <div id="smooth-wrapper">
                    <Navbar />
            <div id="smooth-content">
                <BrowserRouter>
                    <Routes>
                        {routes.map((route, index) => {
                            const Component = route.component;
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={<Component />}
                                />
                            );
                        })}
                    </Routes>
                    <Footer />
                </BrowserRouter>
            </div>
        </div>
    );
};

export default App;
