import LocationGallery from "../../components/locationGallery/locationGallery";
import MapComponent from "../../components/map/map";
import gsap from "gsap";
import "./location.scss";
import { useLayoutEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);
const Location = () => {
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".intro-overlay", {
                opacity: 0,
                duration: 1,
                ease: "power1.in",
                delay: 0.25,
                onComplete: () => {
                    gsap.set(".intro-overlay", { display: "none" });
                },
            });

            gsap.delayedCall(0.2, () => {
                let split = new SplitText(".text-to-animate", {
                    type: "lines",
                });

                gsap.from(split.lines, {
                    scrollTrigger: {
                        trigger: ".text-to-animate", // Pin the text, not the wrapper
                        start: "top center-=64", // Start when the text reaches the middle
                        end: "bottom bottom", // Animate over 100vh
                        scrub: 1,
                        scroller: "#smooth-wrapper",
                        pin: true,
                        pinSpacing: true, // Ensure other content isn't pushed oddly
                    },
                    opacity: 0,
                    y: 0,
                    ease: "back",
                    stagger: 0.2,
                });
            });
            ScrollTrigger.normalizeScroll(true);
            ScrollTrigger.refresh();
        });
        return () => ctx.revert();
    }, []);

    return (
        <>
            <div
                className="location-one"
                style={{ backgroundImage: "url(/images/location/1.jpg)" }}
            >
                {/* <video className="video" loop playsInline autoPlay muted src='/videos/location-banner.mp4'></video> */}
                <div className="intro-overlay"></div>

                <div className="circle">
                    <div className="copy">
                        <h4>THE LOCATION</h4>
                        <h1>
                            PART OF THE FABRIC <br />
                            OF THE WEST END
                        </h1>
                    </div>
                </div>
            </div>
            <div className="location-two">
                <div className="text-wrapper">
                    <h3 className="text-to-animate">
                        One Derry Street is located in the West End
                        just seconds from High Street Kensington Station.
                        <br />
                        The area is synonomous with style and fashion as well as
                        being the home of many major occupiers.
                    </h3>
                </div>
            </div>
            <div className="location-three">
                <MapComponent />
            </div>

            <div className="location-four">
                <div className="gallery one">
                    <LocationGallery
                        images={[
                            "/images/location/gallery/1.jpg",
                            "/images/location/gallery/2.jpg",
                            "/images/location/gallery/3.jpg",
                        ]}
                        captions={[
                            { duration: "10", location: "Design Museum" },
                            { duration: "13", location: "V&A" },
                            { duration: "14", location: "SCIENCE MUSEUM" },
                        ]}
                        copy={{
                            heading: "CULTURAL THREADS",
                            copy: "Kensington is London’s culture centre with many world-famous institutions just a few minutes walk away.",
                        }}
                        id="one"
                    />
                </div>
                <div className="gallery two">
                    <LocationGallery
                        images={[
                            "/images/location/gallery2/1.jpg",
                            "/images/location/gallery2/2.jpg",
                        ]}
                        captions={[
                            { duration: "10", location: "holland park" },
                            { duration: "10", location: "hyde park" },
                        ]}
                        copy={{
                            heading: "SPACE TO BREATHE",
                            copy: "One Derry Street is close to two of London’s most beautiful green spaces, Hyde Park and Holland Park.",
                        }}
                        id="two"
                    />
                </div>
                <div className="gallery three">
                    <LocationGallery
                        images={[
                            "/images/location/gallery3/1.jpg",
                            "/images/location/gallery3/2.jpg",
                            "/images/location/gallery3/3.jpg",
                        ]}
                        captions={[
                            { duration: "10", location: "ZAIKA" },
                            { duration: "03", location: "Jacuzzi" },
                            { duration: "05", location: "paris baguette" },
                        ]}
                        copy={{
                            heading: "HAUTE CUISINE",
                            copy: "Food and drink options are plentiful and range from quick and convenient to Michelin starred institutions",
                        }}
                        id="three"
                    />
                </div>
            </div>

            <div className="location-five">
                <div className="copy-wrapper">
                    <h3>
                        A SHORT CUT TO
                        <br />
                        THE UNDERGROUND
                    </h3>
                    <p>
                        The building features its own dedicated exit from the
                        excellently connected High Street Kensington Underground
                        Station.
                    </p>
                </div>
                <div
                    className="image-wrapper"
                    style={{ backgroundImage: "url(/images/location/2.jpg)" }}
                ></div>
            </div>

            <div className="location-six">
                <div className="column-1">
                    <div className="copy-wrapper">
                        <h1>
                            01
                            <span>MINS</span>
                        </h1>
                        <h2>
                            HIGH STREET
                            <br className="no-mb" /> KENSINGTON
                        </h2>
                        <div className="circles-wrapper">
                            <div className="circle"></div>
                            <div className="circle district"></div>
                        </div>
                        <img
                            className="underground"
                            src="/images/location/underground-beige.svg"
                        />
                    </div>
                </div>
                <div className="column-2">
                    <div className="row one">
                        <div className="copy-wrapper">
                            <h1>
                                02
                                <span> MINS</span>
                            </h1>
                            <h2>NOTTING HILL GATE</h2>
                            <div className="circles-wrapper">
                                <div className="circle"></div>
                                <div className="circle district"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="overground"
                                src="/images/location/overground.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground.svg"
                            />
                        </div>
                    </div>
                    <div className="row two">
                        <div className="copy-wrapper">
                            <h1>
                                02
                                <span> MINS</span>
                            </h1>
                            <h2>SOUTH KENSINGTON</h2>
                            <div className="circles-wrapper">
                                <div className="circle"></div>
                                <div className="circle district"></div>
                                <div className="circle piccadilly"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="overground"
                                src="/images/location/overground.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground.svg"
                            />
                        </div>
                    </div>
                    <div className="row three beige">
                        <div className="copy-wrapper">
                            <h1>
                                04
                                <span> MINS</span>
                            </h1>
                            <h2>PADDINGTON</h2>
                            <div className="circles-wrapper">
                                <div className="circle"></div>
                                <div className="circle district"></div>
                                <div className="circle hammersmith"></div>
                                <div className="circle bakerloo"></div>
                                <div className="circle elizabeth"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="underground"
                                src="/images/location/overground-beige.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground-beige.svg"
                            />
                        </div>
                    </div>
                    <div className="row four beige">
                        <div className="copy-wrapper">
                            <h1>
                                09
                                <span> MINS</span>
                            </h1>
                            <h2>BOND STREET</h2>
                            <div className="circles-wrapper">
                                <div className="circle central"></div>
                                <div className="circle elizabeth"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="underground"
                                src="/images/location/overground-beige.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground-beige.svg"
                            />
                        </div>
                    </div>
                </div>
                <div className="column-3">
                    <div className="row five beige">
                        <div className="copy-wrapper">
                            <h1>
                                10
                                <span> MINS</span>
                            </h1>
                            <h2>VICTORIA</h2>
                            <div className="circles-wrapper">
                                <div className="circle"></div>
                                <div className="circle district"></div>
                                <div className="circle victoria"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="underground"
                                src="/images/location/overground-beige.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground-beige.svg"
                            />
                        </div>
                    </div>
                    <div className="row six">
                        <div className="copy-wrapper">
                            <h1>
                                12
                                <span> MINS</span>
                            </h1>
                            <h2>FARRINGDON</h2>
                            <div className="circles-wrapper">
                                <div className="circle"></div>
                                <div className="circle district"></div>
                                <div className="circle hammersmith"></div>
                                <div className="circle bakerloo"></div>
                                <div className="circle elizabeth"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="underground"
                                src="/images/location/overground.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground.svg"
                            />
                        </div>
                    </div>
                    <div className="row seven beige">
                        <div className="copy-wrapper">
                            <h1>
                                14
                                <span> MINS</span>
                            </h1>
                            <h2>LIVERPOOL STREET</h2>
                            <div className="circles-wrapper">
                                <div className="circle"></div>
                                <div className="circle metropolitan"></div>
                                <div className="circle hammersmith"></div>
                                <div className="circle central"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="underground"
                                src="/images/location/overground-beige.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground-beige.svg"
                            />
                        </div>
                    </div>
                    <div className="row eight">
                        <div className="copy-wrapper">
                            <h1>
                                25
                                <span> MINS</span>
                            </h1>
                            <h2>HEATHROW AIRPORT</h2>
                            <div className="circles-wrapper">
                                <div className="circle piccadilly"></div>
                                <div className="circle elizabeth"></div>
                            </div>
                        </div>
                        <div className="icons-wrapper">
                            <img
                                className="underground"
                                src="/images/location/overground.svg"
                            />
                            <img
                                className="underground"
                                src="/images/location/underground.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Location;
