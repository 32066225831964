import React from "react";
import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <footer>
            <div className="wrapper">
                <div className="container">
                    <div className="image-wrapper">
                        <img src="ui/footer-logo.svg" />
                    </div>
                    <div className="address-copy">
                        <p>
                            1 Derry Street
                            <br />
                            Kensington
                            <br />
                            London W8 5HY
                        </p>
                        <p>
                            33,473 sq ft
                            <br />
                            Available now
                        </p>
                    </div>
                    <div className="download">
                        <h4>DOWNLOAD</h4>
                        <a
                            href="/dl/brochure.pdf"
                            target="_blank"
                            className="arrow-button btn-footer"
                        >
                            <p>BROCHURE</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" fill="#e6e0cc" />
                            </svg>
                        </a>
                        <a
                            href="/dl/One-Derry-Street-Floor-plans.pdf"
                            target="_blank"
                            className="arrow-button btn-footer"
                        >
                            <p>FLOORPLAN</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="11"
                                viewBox="0 0 5 11"
                                fill="none"
                            >
                                <path d="M0 0V11L5 5.5L0 0Z" fill="#e6e0cc" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="container">
                    <div className="agents-wrapper">
                        <div className="agents">
                            <div className="image-wrapper">
                                <img
                                    className="kontor"
                                    src="images/footer/kontor.svg"
                                />
                            </div>
                            <div className="agent">
                                <p>ANDREW GIBSON</p>
                                <a
                                    className="email"
                                    href="mailto:andrew@kontor.com"
                                >
                                    <p>andrew@kontor.com</p>
                                </a>
                                <p>07866 602 381</p>
                            </div>
                            <div className="agent">
                                <p>PAUL GOLD</p>
                                <a
                                    className="email"
                                    href="mailto:paul@kontor.com"
                                >
                                    <p>paul@kontor.com</p>
                                </a>
                                <p>07866 602 381 </p>
                            </div>
                        </div>
                        <div className="agents">
                            <div className="image-wrapper">
                                <img
                                    className="kontor"
                                    src="images/footer/kf.svg"
                                />
                            </div>
                            <div className="agent">
                                <p>SERENA HARWOOD</p>
                                <a
                                    className="email"
                                    href="mailto:serena.harwood@knightfrank.com"
                                >
                                    <p>serena.harwood@knightfrank.com</p>
                                </a>
                                <p>07790 344 116</p>
                            </div>
                            <div className="agent">
                                <p>LYDIA CARTER</p>
                                <a
                                    className="email"
                                    href="mailto:lydia.carter@knightfrank.com"
                                >
                                    <p>lydia.carter@knightfrank.com</p>
                                </a>
                                <p>07929 668 005</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-wrapper">
                <p className="section">© One Derry Street. 2025.</p>
                <div className="flex-wrapper">                   
                    <a href="https://sirendesign.co.uk/">
                        <p>Designed by Siren</p>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
