import { useLayoutEffect } from "react";
import AvailabilityGallery from "../../components/availabilityGallery/availabilityGallery";
import Floorplans from "../../components/floorplans/floorplans";
import Gallery from "../../components/gallery/gallery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import "./availability.scss";

gsap.registerPlugin(ScrollTrigger, SplitText);

const Availability = () => {
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".intro-overlay", {
                opacity: 0,
                duration: 1,
                ease: "power1.in",
                delay: 0.25,
                onComplete: () => {
                    gsap.set(".intro-overlay", { display: "none" });
                },
            });

            gsap.delayedCall(0.2,() => {
                let split = new SplitText(".text-to-animate", {
                    type: "lines",
                });

                gsap.from(split.lines, {
                    scrollTrigger: {
                        trigger: ".text-to-animate", // Pin the text, not the wrapper
                        start: "top center-=64", // Start when the text reaches the middle
                        end: "bottom bottom", // Animate over 100vh
                        scrub: 1,
                        scroller: "#smooth-wrapper",
                        pin: true,
                        pinSpacing: true, // Ensure other content isn't pushed oddly
                    },
                    opacity: 0,
                    y: 0,
                    ease: "back",

                    stagger: 0.2,
                });
            });
            ScrollTrigger.normalizeScroll(true);
            ScrollTrigger.refresh();
        });
        return () => ctx.revert();
    }, []);

    return (
        <>
            <div
                className="availability-one"
                style={{ backgroundImage: "url(/images/availability/1.jpg)" }}
            >
                <div className="intro-overlay"></div>
                <div className="circle">
                    <div className="copy">
                        <h4>AVAILABILITY</h4>
                        <h1>
                            MADE TO
                            <br />
                            MEASURE
                        </h1>
                    </div>
                </div>
            </div>

            <div className="availability-two">
                <div className="text-wrapper">
                    <h3 className="text-to-animate">
                        The characterful warehouse floors have been carefully
                        reconsidered as modern workspace. The office space is
                        set over four floors with a roof terrace for
                        entertaining and end-of-journey facilities on the lower
                        ground floor.
                    </h3>
                </div>
            </div>

            <div className="availability-three">
                <AvailabilityGallery
                    images={[
                        "/images/availability/gallery/1.jpg",
                        "/images/availability/gallery/2.jpg",
                        "/images/availability/gallery/3.jpg",
                        "/images/availability/gallery/4.jpg",
                        "/images/availability/gallery/5.jpg",
                        "/images/availability/gallery/6.jpg",
                    ]} captions={['Second','Ground','Ground','First','Ground','Ground']}                />
            </div>

            <div className="availability-four">
                <Floorplans />
            </div>

            <div className="availability-five">
                <Gallery
                    images={[
                        "/images/availability/gallery2/1.jpg",
                        "/images/availability/gallery2/2.jpg",
                        "/images/availability/gallery2/3.jpg",
                        "/images/availability/gallery2/4.jpg",
                        "/images/availability/gallery2/5.jpg",
                        "/images/availability/gallery2/6.jpg",
                    ]}
                    captions={
                        [
                            'Entrance Arrival',
                            'Lifts',
                            'End of-journey facilities',
                            'Ground floor reception',
                            'Ground floor reception',
                            'End of-journey facilities',
                            
                            
                        ]
                    }
                />
            </div>
        </>
    );
};

export default Availability;
