import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "./gallery.scss";

interface GalleryProps {
    images: string[];
    captions?: string[];
}

const Gallery: React.FC<GalleryProps> = ({ images, captions }) => {
    return (
        <>
            <Swiper
                id="gallery"
                spaceBetween={16}
                breakpoints={{
                    768: { slidesPerView: 1 },
                    1024: { slidesPerView: 2.5 },
                }}
                centeredSlides={true}
                loop={true}
                modules={[Autoplay]}
                autoplay={{ delay: 5000 }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className="image-wrapper"
                            style={{ backgroundImage: `url(${image})` }}
                        >
                            {captions && captions[index] && (
                                <div className="caption flip">{captions[index]}</div>
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default Gallery;
